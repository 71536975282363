const DashboardCode = require('./DashboardCode')
const DashboardPass = require('./DashBoardPassword')
//const config = require('../config')

class Login {
    constructor(code, pass, updateLocalStorage = true) {
        this.code = new DashboardCode(code)
        this.pass = new DashboardPass(pass)
        this._updateLocalStorage = updateLocalStorage
        this.token = ''
    }

    _save(token, code, pass) {
        if (!this._updateLocalStorage)
            return

        window.localStorage.setItem('token', token)
        window.sessionStorage.setItem('code', code)

        if (pass == null){
            window.sessionStorage.setItem('pass', '')
        }
        else{
            window.sessionStorage.setItem('pass', pass) 
        }
        
        if (window.localStorage.getItem('isDisplayDescription') == '') {
            window.localStorage.setItem('isDisplayDescription', 'fas fa fa-angle-double-up')
            window.localStorage.setItem("mensagemIconeInformacoes","Mostar somente informações principais.");
        }

        if (window.localStorage.getItem('IconeFiltro') == '') {
            window.localStorage.setItem('IconeFiltro', 'fas fal fa-exclamation-triangle')
        }

    }

    async doLogin() {
        // const urlGet = 'http://192.168.100.132:9091/validatetoken/' + this.code.value.toUpperCase();

        //  const response = await fetch(urlGet, { method: 'GET' })
        //  if (response.status == 200) {
        ///      this.token = await response.text()
        //        this._save(this.token, this.code.value)
        //        return true;
        //    }
        //   else
        //         this._save('', '');
        //   return false;
        this._save('', this.code.value, this.pass.value)
        return true;
    }
}

module.exports = Login