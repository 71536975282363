import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'

const routes = [
  {path: '/', name: 'Login', component: Login},
  {path: '/:code', name:'Dashboard', component: () => import("../views/Dashboard.vue"),
  query: {
    intervalo: 1,
    cpsweb : false
  }},
  {path: '/listaos/:code', name:'OSList', component: () => import("../views/OSList.vue")},
  {
    path: '/admin', 
    name: 'Admin', 
    component: () => import("../views/Admin.vue"),
    beforeEnter: () => {
      if (window.localStorage.getItem('admin-token'))
        return '/admin/root'
    }
  },
  {
    path: '/admin/root', 
    name: 'Test tools', 
    component: () => import("../views/AdminRoot.vue"), 
    beforeEnter: () => {
      if (!window.localStorage.getItem('admin-token'))
        return '/admin'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
