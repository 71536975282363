<template>
    <div class="loading-modal">
        <div class="loading-modal-content">
            <h1>
                <template v-if="title">{{title}}</template>
                <template v-else>Carregando...</template>
            </h1>
            <p v-if="message">{{message}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name:'LoadingModal',
    props: {
        title: String,
        message: String
    }
}
</script>

<style scoped>
.loading-modal {
    height:100vh;
    width:100vw;
    background-color:rgba(0,0,0,0.7);
    display:flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
}

.loading-modal-content {
    background-color: white;
    padding: 1vw 3vw;
    border-radius: 1vw;
}
</style>