<template>
    <div class="container main-container mt-5">
        <div class="row justify-content-center">
            <img src="../../assets/logo.png" alt="ApontaFácil logo" class="img-fluid col-8">
        </div>
        <form @submit.prevent="doLogin">
            <div class="row my-4 text-center justify-content-center">
                <h6 class="col-12">Informe o código do Dashboard, para mais de um utilize “-“. Exemplo “ABCDEF-FEDCBA”</h6>
                <div class="col-6">
                    <input type="string" class="form-control text-center" step="1" autofocus 
                        style="text-transform: uppercase;" v-model="code" @keypress="clearTimeout" :disabled="loading">

                </div>
            </div>
            <div class="row my-4 text-center justify-content-center">
                <h6 class="col-12" style="margin-bottom: 20px;">Informe o tempo de intervalos entre os dashboard em minutos</h6>
               
                <div class="col-6">
                    <input type="string" class="form-control text-center"  autofocus
                        style="text-transform: uppercase;" v-model="timerDash" @keypress="clearTimeout" :disabled="loading">

                </div>
              
                <h6 style="margin-top: 20px;">Caso deseje usar o intervalo em segundos preencha o campo usando o exemplo a seguir:
                    <br>
                    <br>
                        Tempo em segundos : 15s = 0.25 
                    <br>
                    <br>
                       Tempo em minutos e segundos : 1m:30s = 1.5 
                </h6>
            </div>
            <div class="row my-4 text-center justify-content-center">
                <h6 class="col-12">Senha</h6>

                <div class="col-6">
                    <input class="form-control text-center" :type="showPassword"  step="1" v-model="pass">

                </div>
                <div class="icon" @click="OnDisplayIcon()"><i class="fas fa-low-vision"></i></div>
            </div>
            <div class="row justify-content-center">
                <button class="btn btn-primary col-5 col-md-4" :disabled="loading">
                    <template v-if="!loading">Acessar</template>
                    <template v-else><i class="fa fa-spinner fa-spin"></i> Carregando...</template>
                </button>
            </div>
        </form>
        <div class="mt-5 text-center" v-if="false">
            <p>Acesso automático usando o código acima em 10 segundos...</p>
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated"
                    :style="{ width: autoLogin.width + '%' }" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                    aria-valuemax="10"></div>
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>

<script>
import Login from '../../classes/Login'
import Swal from 'sweetalert2'

export default {
    name: 'LoginFormBootstrap',
    data() {
        return {
            code: '',
            pass: '',
            timerDash : null,
            loading: false,
            autoLogin: {
                enabled: true,
                timer: 10000,
                width: 100
            },
            showPassword: "password"
        }
    },

    watch: {
        code() {
            if (this.autoLogin.enabled) {
                this.autoLogin.enabled = false
                window.clearTimeout(this.autoLogin.timer)
            }
        }
    },
    methods: {
        OnDisplayIcon() {
            if (this.showPassword == 'string') {
                this.showPassword = 'password';
            } else {
                this.showPassword = 'string';
            }
        },
        async doLogin() {
            //console.log('methods  async doLogin()')
            window.clearTimeout(this.autoLogin.timer)
            this.autoLogin.enabled = false
            this.loading = true
            try {
                const login = new Login(this.code, this.pass)
                if (Number.parseInt(login.code.value, 2) == (new Date().getFullYear() - 2000))
                    this.$router.push('/simul')
                    
                else {
                    try {
                        if (await login.doLogin()){
                            if (this.timerDash ==  null) {
                                this.$router.push(`/${login.code.value.toUpperCase()}`)     
                            }
                            else {
                                this.$router.push(`/${login.code.value.toUpperCase()}?intervalo=${this.timerDash}`)
                            }
                            
                            }
                        else{
                            Swal.fire('Não é possível conectar a este dashboard', 'Código desconhecido', 'error')}
                    }
                    catch (e) {
                        Swal.fire(
                            !e.error ? 'Não foi possível acessar o dashboard' : 'Erro do sistema',
                            e.msg || e,
                            'error'
                        )
                    }
                }
            }
            catch (e) {
                Swal.fire('Código inválido', e, 'error')
            }
            this.loading = false
        }
    },
    created() {
        const setAutoLoginTimer = () => {
            this.autoLogin.timer = window.setTimeout(() => {
                if (this.autoLogin.width > 0) {
                    this.autoLogin.width -= 1
                    setAutoLoginTimer()
                }
                else {
                    this.doLogin()
                }
            }, 100)
        }

        this.code = window.sessionStorage.getItem('code') || ''
        this.pass = window.sessionStorage.getItem('pass')

        this.autoLogin.enabled = false
        if (this.autoLogin.enabled)
            setAutoLoginTimer()
    },
    unmounted() {
        window.clearTimeout(this.autoLogin.timer)
    }
}
</script>

<style scoped>
.main-container {
    max-width: 500px
}

.fas {
    margin-top: 20px;
}
</style>