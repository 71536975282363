class DashboardCode {
    constructor(code) {
        code = typeof code == 'string' ? code.trim() : ''
        if (!code)
            throw `Código vazio: ${code}`
        this.value = code.toLowerCase()
    }
}



module.exports = DashboardCode