import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap'
import '../scss/custom.scss'


window.app = createApp(App).use(router).mount('#app')
//console.log(window.localStorage.length)
if (window.localStorage.length <= 4) { // -> conta o total de local storage pra saber se foi criado ou nao a variavel isDisplayDescription
    window.localStorage.setItem('isDisplayDescription', 'fas fa fa-angle-double-up')
    window.localStorage.setItem("mensagemIconeInformacoes","Mostar somente informações principais.");
    window.localStorage.setItem('IconeFiltro', 'fas fal fa-exclamation-triangle')
    window.localStorage.setItem('mensagemIconeFiltro', 'Filtrar todos os apontamentos.')
}

