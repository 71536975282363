<template>
    <loading-modal v-if="loading"/>
    <login-form-bootstrap @loading="loading = true"/>
</template>

<script>
import LoadingModal from '../components/LoadingModal.vue'
import LoginFormBootstrap from '../components/Login/LoginFormBootstrap.vue'

export default {
    name: 'Login',
    components: {LoadingModal, LoginFormBootstrap},
    data() {
        return {
            loading: false
        }
    }
}
</script>

<style scoped>


</style>